import { CONVEYANCE_TYPES } from '@koala/sdk/v4';
import { useEffect, useState } from 'react';
import { Modal } from '@/components/uielements/modal';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { DELIVERY_TIME_WANTED_MODES } from '@/constants/global';
import { HandoffTimePicker } from '@/features/handoff/time-picker';
import { useLocation } from '@/features/locations/service';
import { useDispatch, useSelector } from '@/redux';
import basketActions from '@/redux/basket/actions';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { fetchMenu } from '@/services/locations.service';
import { basketCheckInvalidItems } from '@/utils/basket';
import { sessionStorageBasket } from '@/utils/global';

export function TimePicker() {
  const [confirmChange, setConfirmChange] = useState<string>();
  const dispatch = useDispatch();

  const { type, time_wanted, location } = useSelector(selectConveyance);
  const { data, isSuccess } = useLocation(location);

  // this reset is necessary to ensure any updates
  // to the time picker show the confirm modal if necessary
  useEffect(() => {
    setConfirmChange(undefined);
  }, [time_wanted]);

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      <HandoffTimePicker
        stack={false}
        onChange={async (value) => {
          // Check for an existing basket
          const localBasket = sessionStorageBasket.get();

          if (localBasket) {
            // fetch a menu for the current location and wanted at time
            const [response] = await fetchMenu({
              id: data.data.id,
              wantedAt: value !== DELIVERY_TIME_WANTED_MODES.ASAP ? value : undefined,
            });

            // Ensure all items in cart exists on a newly fetched menu
            const invalidItems = basketCheckInvalidItems(
              localBasket.content.basket_items,
              response,
            );

            // confirm restarting if there is a discrepancy
            if (invalidItems.length > 0) {
              setConfirmChange(value);

              return;
            }
          }

          dispatch(conveyanceModeActions.setWantedTime(value, data.data.id, type));
        }}
        isSubmitting={false}
        location={data.data}
        handoffType={CONVEYANCE_TYPES.PICKUP}
        supportsAsap={false}
        value={confirmChange ?? time_wanted}
      />

      <Modal.Container
        requestVisibility={Boolean(confirmChange)}
        onCancel={() => {
          setConfirmChange(undefined);
        }}
      >
        {(toggle) => (
          <>
            <Modal.Title>Restart order</Modal.Title>
            <Modal.Content>
              <p>
                Some items in your basket are unavailable for your new order time. Would you like to
                proceed with changing your order time?
              </p>
            </Modal.Content>

            <Modal.Footer>
              <Modal.Cancel>Keep existing</Modal.Cancel>

              <StyledPrimaryButton
                data-css-override="StyledPrimaryButton"
                type="submit"
                onClick={() => {
                  toggle();

                  dispatch(basketActions.destroyBasket());

                  dispatch(conveyanceModeActions.setWantedTime(confirmChange, data.data.id, type));
                }}
              >
                Change Time
              </StyledPrimaryButton>
            </Modal.Footer>
          </>
        )}
      </Modal.Container>
    </>
  );
}
