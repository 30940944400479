globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"coo-webapp-2025-01-24-1737738904"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { ENV, SENTRY_DSN } from '@/constants/envConfig';
import * as Sentry from '@sentry/nextjs';

/**
 * @TODO investigate mechanisms to declutter Sentry if we log a lot of junk.
 * @see https://docs.sentry.io/clients/javascript/tips/
 */
Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: ENV === 'dev' ? 0 : 0.6,
  tracePropagationTargets: ['localhost', /^https:\/\/prod-coo-api\.chowly\.io\/v1/],
  replaysOnErrorSampleRate: 0.8,
  denyUrls: ['https://siteimproveanalytics.com', 'https://maps.googleapis.com'],
});
