import { type WebConfig } from '@koala/sdk';
import styled from 'styled-components';
import { SPACING_SCALE } from '@/constants/styles';
import { parseCustomUserAgent } from '@/utils/mobile';

interface Theme {
  theme: WebConfig;
}

/* using isCOOMobile_iOS to override default User Agent styles on coo-mobile before `normalize.css` is able to override them */
const isCOOMobile_iOS =
  typeof window !== 'undefined' && window.navigator && parseCustomUserAgent().mobile_ios;

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100vw;
  ${isCOOMobile_iOS && `margin: -8px;`}
`;

export const Background = styled.div`
  background-color: black;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100vh;
  width: 100%;
`;

export const Hero = styled.img`
  background-repeat: no-repeat;
  height: 100%;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
`;

export const Panel = styled.div`
  ${({ theme }: Theme) => `
    --panel__background-color: ${theme.android_home.background_color};
  `}

  align-self: end;
  background-color: var(--panel__background-color);
  border-radius: ${SPACING_SCALE.THREEXS}px ${SPACING_SCALE.THREEXS}px 0 0;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  gap: ${SPACING_SCALE.LARGE}px;
  padding: ${SPACING_SCALE.LARGE}px ${SPACING_SCALE.SMALL}px ${SPACING_SCALE.XL}px;
`;
