import styled from 'styled-components';
import { Container } from '../home/header/header.styles';
import { Panel } from '../home/home.styles';

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
`;

export const Header = styled(Container)``;

export const Body = styled(Container)``;

export const BlurOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
  pointer-events: none;
`;

export const Content = styled(Panel)`
  boxshadow: '0px 0px 20px rgba(0, 0, 0, 0.2)';
  zindex: 2;
  gap: 7px;
`;
