import {
  sdkUtils,
  type BasketItem,
  type BasketOrder,
  type MenuProduct,
  type MenuProductOptionGroup,
} from '@koala/sdk';
import {
  ItemContainer,
  Image,
  Summary,
  Details,
  Modifier,
  Label,
  Price,
  CartModalDescription,
  RemoveItemButton,
} from './items.styles';
import { AllergensMenuCard } from '@/components/allergens';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { XSvg } from '@/components/uielements/X';
import { Box } from '@/components/uielements/box/box';
import { ItemRecipient } from '@/components/uielements/itemRecipient';
import { Modal } from '@/components/uielements/modal';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { Stack } from '@/components/uielements/stack/stack';
import { PRODUCT_LOCATION_LABELS } from '@/constants/checkout';
import { ALLERGEN_CARD_MODE } from '@/constants/global';
import { useMenu } from '@/features/menu/service';
import { useDispatch, useSelector } from '@/redux';
import { selectBasketSlice } from '@/redux/basket';
import basketActions from '@/redux/basket/actions';
import { selectCart } from '@/redux/basket/reducer';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import customizeActions from '@/redux/customize/actions';
import { prepareExistingOptionsObject, toDollars } from '@/utils/basket';
import { getProductImageSrc, hasProductImage } from '@/utils/imageHelper';
import { getAllProductOptionAllergens } from '@/utils/menu';

interface Props {
  index: number;
  basketItem: BasketItem;
  basketProduct: MenuProduct;
  removeItem?: (item: BasketItem, index: number) => void;
  editProduct?: (item: BasketItem, index: number) => void;
  showImage: boolean;
  showDietaryPreferences: boolean;
  showSpecialInstructions: boolean;
}

export const Item = ({
  basketItem,
  basketProduct,
  index,
  editProduct,
  removeItem,
  showImage,
  showDietaryPreferences,
  showSpecialInstructions,
}: Props) => {
  const isImageShown = showImage && hasProductImage(basketProduct);

  if (!basketProduct || !basketItem) {
    return null;
  }

  return (
    <ItemContainer data-testid="basket-item">
      {isImageShown && (
        <Image
          $canEdit={Boolean(editProduct)}
          onClick={() => {
            if (editProduct) {
              editProduct(basketItem, index);
            }
          }}
          src={getProductImageSrc(basketProduct, {
            width: 80,
          })}
          alt={basketProduct.name}
          width={80}
        />
      )}

      <Summary
        onClick={() => {
          if (editProduct && !removeItem) {
            editProduct(basketItem, index);
          }
        }}
      >
        <Stack>
          <Label
            data-testid="basket-item-label"
            tabIndex={0}
            as={editProduct ? 'button' : 'span'}
            aria-label={`Click to edit ${basketProduct.name}`}
            onClick={() => {
              if (editProduct) {
                editProduct(basketItem, index);
              }
            }}
            $canEdit={Boolean(editProduct)}
          >
            {/* Item info  */}
            {basketProduct.name}
          </Label>

          <Price>${toDollars((basketProduct?.final_cost ?? 0) * basketItem.quantity)}</Price>

          {removeItem && (
            <Modal.Container
              trigger={(open) => (
                <RemoveItemButton
                  data-testid="remove-item-button"
                  aria-label={`Click to delete ${basketProduct.name}`}
                  onClick={open}
                >
                  <XSvg size={10} />
                </RemoveItemButton>
              )}
            >
              {(toggle) => (
                <>
                  <Modal.Title>Remove item</Modal.Title>
                  <Modal.Content>
                    <CartModalDescription>
                      <StringAccessor
                        accessor="remove_item.confirm_prompt"
                        html={true}
                        dataObj={{ productToRemove: basketProduct.name }}
                      />
                    </CartModalDescription>
                  </Modal.Content>
                  <Modal.Footer>
                    <Modal.Cancel>Cancel</Modal.Cancel>
                    <StyledPrimaryButton
                      data-css-override="StyledPrimaryButton"
                      onClick={() => {
                        toggle();
                        removeItem(basketItem, index);
                      }}
                    >
                      Remove
                    </StyledPrimaryButton>
                  </Modal.Footer>
                </>
              )}
            </Modal.Container>
          )}
        </Stack>

        <Details>
          {/* Item modifiers */}
          <Modifier data-css-override="StyledProductModifiers">
            {
              // @ts-expect-error
              basketProduct.surfaceable_option_groups
                .map((og: MenuProductOptionGroup) =>
                  og.options
                    .filter((option) => Boolean(option?.quantity ?? 0 > 0))
                    .map((option) =>
                      og.supports_quantity_selection
                        ? `${option.quantity} x ${option.name}`
                        : option.name,
                    )
                    .join(', '),
                )
                .filter((value) => value !== '')
                .join(', ')
            }
          </Modifier>

          <Modifier data-css-override="StyledProductModifiers">
            Quantity: {basketItem.quantity}
          </Modifier>

          {/* Item instructions */}
          {basketItem.special_instructions && showSpecialInstructions && (
            <Modifier data-css-override="StyledProductModifiers" style={{ wordBreak: 'break-all' }}>
              &quot;{basketItem.special_instructions}&quot;
            </Modifier>
          )}

          {/* Item recipient */}
          {basketItem.recipient && (
            <ItemRecipient recipient={basketItem.recipient} themeContext="sidecart" />
          )}

          {/* Dietary preferences */}
          {showDietaryPreferences && basketItem.options.length > 0 && (
            <AllergensMenuCard
              productAllergens={basketProduct}
              selectedOptions={basketItem.options}
              compileAllergensMethod={getAllProductOptionAllergens}
              mode={ALLERGEN_CARD_MODE.DETAIL}
            />
          )}
        </Details>
      </Summary>
    </ItemContainer>
  );
};

export const UnavailableItem = ({ name }: { name: string }) => {
  return (
    <ItemContainer>
      <Summary>
        <Stack>
          <Label $canEdit={false}>
            <span>{name}</span>
          </Label>
        </Stack>
        <Details>
          <Modifier data-css-override="StyledProductModifiers">
            * Item not currently available at this location
          </Modifier>
        </Details>
      </Summary>
    </ItemContainer>
  );
};

interface Items {
  items: BasketOrder;
}

interface Product {
  item: BasketItem;
  final: MenuProduct;
  menuProduct: MenuProduct;
  index?: number;
}

export function Items({ items }: { items: Product[] }) {
  const { content: basketContent } = useSelector(selectBasketSlice);
  const { time_wanted } = useSelector(selectConveyance);
  const { basketMenu } = useSelector((state) => state.app.menu);
  const { showProductImages, showDietaryPreferences, showSpecialInstructions } =
    useSelector(selectCart);

  const { categories } = useMenu({
    id: basketContent.location.id,
    wantedAt: time_wanted,
  });

  const dispatch = useDispatch();

  const handleEditProduct = (item: BasketItem, index: number) => {
    const product: MenuProduct | undefined = sdkUtils.findMenuItemById(item.product.id, basketMenu);

    // Failsafe for previous `options` format from existing carts
    const existingOptions = prepareExistingOptionsObject(item.options);

    dispatch(
      customizeActions.setProduct({
        label: PRODUCT_LOCATION_LABELS.BASKET,
        // @ts-expect-error: mismatched types
        product,
        categories,
        item,
        index,
        existingOptions,
      }),
    );
  };

  return (
    <>
      {items?.map((value, index) => {
        return value?.item && value?.final ? (
          <Box
            key={`${value.item.product.id}-${index}`}
            overrides={{
              borderBlockStart: '1px solid var(--sidecart__divider-color)',
              padding: 'var(--size-6)',
            }}
          >
            <Item
              index={value.index ? value.index : index}
              basketItem={value.item}
              basketProduct={value.final}
              removeItem={(basketItem, index) => {
                dispatch(basketActions.removeItem(index, basketItem));
              }}
              editProduct={handleEditProduct}
              showImage={showProductImages}
              showDietaryPreferences={showDietaryPreferences}
              showSpecialInstructions={showSpecialInstructions}
            />
          </Box>
        ) : null;
      })}
    </>
  );
}
