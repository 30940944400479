import { type WebConfig } from '@koala/sdk';
import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { SPACING_SCALE } from '@/constants/styles';

interface Theme {
  theme: WebConfig;
}

export const StyledNavigationListOverrides = styled.div`
  ${({ theme }: Theme) => `
    --navigation-item__font-family: ${theme.android_home.selection_view_font.font_family};
    --navigation-item__color: ${theme.android_home.selection_view_font.color ?? 'inherit'};
  `}
`;

export const StyledBadge = styled.div`
  ${({ theme }: Theme) => `
    --badge__font-family: ${theme.android_home.badge_count_font.font_family};
  `}
`;

export const CTA = styled.div`
  display: flex;
  gap: ${SPACING_SCALE.THREEXS}px;

  button {
    width: 100%;
  }
`;

export const OrderNowButton = styled(StyledPrimaryButton)`
  ${({ theme }: Theme) => `
    background-color: ${theme.android_home.order_now_button_background_color};
    border-radius: ${theme.android_home.order_now_button_border_radius ?? 'inherit'}px;
    color: ${theme.android_home.order_now_button_font.color ?? 'inherit'};
    font-family: ${theme.android_home.order_now_button_font.font_family};
  `}
`;

export const ReorderButton = styled(StyledPrimaryButton)`
  ${({ theme }: Theme) => `
    background-color: ${theme.android_home.reorder_button_background_color};
    border-radius: ${theme.android_home.reorder_button_border_radius ?? 'inherit'}px;
    border-width: ${theme.android_home.reorder_button_border_width}px;
    color: ${theme.android_home.reorder_button_font.color ?? 'inherit'};
    font-family: ${theme.android_home.reorder_button_font.font_family};
  `}
`;
