import { type BasketItem } from '@koala/sdk';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type CSSProperties } from 'react';
import { ConnectedCartIcon } from '../account/uielements';
import { styled } from '@/components/content/stitches';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { ROUTES } from '@/constants/routes';
import { useDispatch, useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import globalActions from '@/redux/global/actions';
import { getBasketItemsCountWithQuantity } from '@/utils/basket';
import { getLocationId } from '@/utils/locations';

const getBasketButtonContents = (
  basketItems: BasketItem[],
  css: {
    backgroundColor: string;
    color: string;
  },
) => {
  const count = getBasketItemsCountWithQuantity(basketItems);

  return (
    <>
      <ConnectedCartIcon />

      {Boolean(count) && <Quantity css={css}>{count}</Quantity>}
    </>
  );
};

interface BasketProps {
  //showOnMobile is a flag for just showing the Basket IF mobile size
  showOnMobile: boolean;
}

export function Basket(props: BasketProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  const { content, location } = useSelector((state) => state.app.basket);

  const { active_background_color, border_radius, active_font_color, active_border_color, font } =
    useConfigOverride('header');

  const overrides: CSSProperties = {
    backgroundColor: active_background_color ?? 'inherit',
    borderRadius: `${border_radius ?? 0}px`,
    color: active_font_color ?? 'inherit',
    borderColor: active_border_color ?? 'inherit',
    margin: '2px',
    ...getFontStyles(font, ['font_family', 'font_size', 'font_weight', 'line_height']),
  };

  //TODO: There is probably a better way to do this, inlining allowed me access to the props, which meant I could dynamically
  //change the elements display. So if showOnMobile, display on all screens but desktop.
  //Originally tried a simple .mobile_btn class in global.css, but some post-process css stuff happens in this app where
  //display gets modified out of scope of header.tsx and basket.tsx, this became the only solution given the time
  const Element = styled('button', {
    alignItems: 'center',
    border: '1px solid black',
    cursor: 'pointer',
    //By default, display the button, then at different break points do the opposite.
    //app changes header design after @md breakpoint, so showOnMobile will change its design
    display: props.showOnMobile ? 'flex' : 'none',
    height: '$9',
    justifyContent: 'center',
    gap: '$1',
    minWidth: '$9',
    padding: '0 $3',

    '@md': {
      display: !props.showOnMobile ? 'flex' : 'none',
    },

    'img, svg': {
      height: '$4',
    },
  });

  const quantityOverrides = {
    backgroundColor: active_font_color ?? 'inherit',
    color: active_background_color ?? 'inherit',
  };

  if (router?.pathname === ROUTES.STORE || Object.keys(location).length === 0) {
    return (
      <Element
        data-testid="basket-button"
        onClick={() => dispatch(globalActions.toggleBasket(true))}
        className={CSS_CLASSES.GLOBAL_HEADER.ORDER_NOW}
        style={overrides}
      >
        {getBasketButtonContents(content.basket_items, quantityOverrides)}
      </Element>
    );
  }

  return (
    <Link
      href={`/store/${getLocationId(location, webConfig)}/${location.label}?openCart=true`}
      legacyBehavior
    >
      <Element
        data-testid="basket-button"
        as="span"
        className={CSS_CLASSES.GLOBAL_HEADER.ORDER_NOW}
        style={overrides}
      >
        {getBasketButtonContents(content.basket_items, quantityOverrides)}
      </Element>
    </Link>
  );
}

const Quantity = styled('span', {
  alignItems: 'center',
  borderRadius: '100%',
  display: 'flex',
  height: '$3-5',
  fontSize: '8px',
  fontWeight: 'bold',
  justifyContent: 'center',
  width: '$3-5',
});
