import { type WebConfig } from '@koala/sdk';
import styled from 'styled-components';
import { SPACING_SCALE } from '@/constants/styles';

interface Theme {
  theme: WebConfig;
}

interface Button {
  theme: WebConfig;
  signedIn: boolean;
}

export const Container = styled.header`
  ${({ theme }: Theme) => `
    --container-title__color: ${theme.android_home.header_font.color ?? 'inherit'};
    --container-title__font-family: ${theme.android_home.header_font.font_family};
    --container-subtitle__color: ${theme.android_home.sub_header_font.color ?? 'inherit'};
    --container-subtitle__font-family: ${theme.android_home.sub_header_font.font_family};
  `}

  display: flex;
  flex-direction: column;
  gap: ${SPACING_SCALE.XS}px;

  text-align: center;

  h1,
  p {
    margin: 0;
  }

  h1 {
    color: var(--container-title__color);
    font-family: var(--container-title__font-family);
    line-height: normal;
  }

  p {
    color: var(--container-subtitle__color);
    font-family: var(--container-subtitle__font-family);
    line-height: 1.5;
  }
`;

export const Button = styled.button<Button>`
  ${({ theme, signedIn }) => `
    --cta-link__font-family: ${
      signedIn
        ? theme.android_home.my_account_button_font.font_family
        : theme.android_home.sign_in_button_font.font_family
    };
    --cta-link__color: ${
      signedIn
        ? theme.android_home.my_account_button_font.color ?? 'inherit'
        : theme.android_home.sign_in_button_font.color ?? 'inherit'
    };
  `}

  appearance: none;
  background-color: transparent;
  border: none;
  color: var(--cta-link__color);
  font-family: var(--cta-link__font-family);
  font-weight: 700;
`;
