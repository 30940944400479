import {
  add,
  differenceInDays,
  parse,
  format,
  isBefore,
  isAfter,
  sub,
  formatISO,
  getDay,
  getISODay,
  isSameDay,
  startOfDay,
  isValid,
  endOfDay,
  parseISO,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { type DateObject } from '@/types/dates';

/**
 * @param   {number} utcOffset
 * @returns {string} a valid offset formatted as HH:MM (ex: -8 to -08:00, 5.5 to +05:30)
 */
export const getSanitizedOffset = (utcOffset: number): string => {
  if (utcOffset === 0) {
    return '+00';
  }

  // we are converting a numeric offset (ex: -8, 5.5, 0, etc)
  // into a string so first we see if it's a positive or negative integer
  const prepend = utcOffset < 0 ? '-' : '+';

  // next, we use Math.abs to ensure all values are position and split them to test for timezone offsets like 5.5
  const [hours, minutes] = Math.abs(utcOffset).toString().split('.');
  const padding = hours.length === 1 ? '0' : '';

  // if we have minutes, it means the value was something like 1.5 or 5.5,
  // so we compute and output the minutes to the minutes value (otherwise, we default to 00)
  const append = minutes !== undefined ? 60 * (Number(minutes) / 10) : '00';

  return `${prepend}${padding}${hours}:${append}`;
};

/**
 * getTimezoneOffset gets the offset in minutes from UTC time
 * for example: Central Standard Time is -360
 *
 * However, during Daylight Savings Time (DST) we jump ahead an hour and CST is now -300
 * This function checks January 1st's offset (a date we know is not DST)
 * and the offset of a passed in date.
 *
 * The value is either 0 or -1, which can then be applied to a date to subtract 0 or 1 hours
 *
 * @param   {Date} date
 * @returns {number} either 0 (non DST dates) or -1 (DST dates) depending on if DST is active for a date
 */
const getDSTOffset = (date: Date = new Date()): number => {
  const jan = new Date(date.getFullYear(), 0, 1);

  return (date.getTimezoneOffset() - jan.getTimezoneOffset()) / 60;
};

/**
 * @returns {string} a matching timezone offset formatted as HH (example: "-08")
 */
export const getClientOffset = (): string => {
  return getSanitizedOffset(getDSTOffset() + new Date().getTimezoneOffset() / -60);
};

/**
 * @param   {DateObject} date
 * @returns {Date} a date object with either the current or passed in date and time
 */
export const getDateObject = (date?: DateObject) => {
  if (date instanceof Date) {
    return date;
  }

  return date ? new Date(date) : new Date();
};

/**
 * @param   {DateObject} date
 * @returns {Date} a date object with the passed in date and time
 */
export const getDate = (date: string, format?: string): Date =>
  format ? parse(date, format, new Date()) : getDateObject(date);

/**
 * @param   {DateObject} date
 * @param   {string}     dateFormat
 * @returns {string} a date string matching the provided format
 */
export const formatDate = (date: DateObject = null, dateFormat: string): string =>
  format(getDateObject(date), dateFormat);

/**
 * @param   {DateObject} date
 * @returns {string} an ISO date string
 */
export const getISODate = (date: DateObject = null) => formatISO(getDateObject(date));

/**
 * @param   {DateObject} date
 * @returns {string} an ISO date string in UTC time
 */
export const getISODateInUTC = (date: DateObject = null): string => formatISO(getUTCDate(date));

/**
 * @param   {DateObject} date
 * @param   {DateObject} comparedTo
 * @returns {number} the number of days between two dates
 */
export const getDaysBetweenTwoDates = (date: DateObject = null, comparedTo: DateObject): number =>
  differenceInDays(getDateObject(date), getDateObject(comparedTo));

/**
 * @param   {DateObject} date
 * @param   {DateObject} comparedTo
 * @returns {boolean} whether the date is the same or after the date it's being compared to
 */
export const isDateSameOrAfter = (date: DateObject = null, comparedTo: DateObject): boolean =>
  getDateObject(date).getTime() >= getDateObject(comparedTo).getTime();

/**
 * @param   {DateObject} date
 * @param   {DateObject} comparedTo
 * @returns {boolean} whether the date is before the date it's being compared to
 */
export const isDateBefore = (date: DateObject = null, comparedTo: DateObject): boolean =>
  getDateObject(date).getTime() < getDateObject(comparedTo).getTime();

/**
 * @param   {DateObject} date
 * @param   {DateObject} comparedTo
 * @returns {boolean} whether the date is the same or before the date it's being compared to
 */
export const isDateSameOrBefore = (date: DateObject = null, comparedTo: DateObject): boolean =>
  getDateObject(date).getTime() <= getDateObject(comparedTo).getTime();

/**
 * @param   {DateObject}       date
 * @param   {DateObject}       comparedTo
 * @returns {boolean} whether two dates are the same based on the untis parameter (same day, same month, etc)
 */
export const isDateSame = (date: DateObject = null, comparedTo: DateObject): boolean =>
  getDateObject(date).getTime() === getDateObject(comparedTo).getTime();

/**
 * @param   {DateObject} date
 * @param   {DateObject} comparedTo
 * @returns {boolean} whether two dates are the same day
 */
export const isDaySame = (date: DateObject = null, comparedTo: DateObject): boolean =>
  isSameDay(getDateObject(date), getDateObject(comparedTo));

/**
 * @param   {DateObject} date
 * @param   {number}     amount
 * @param   {string}     units
 * @returns {Date} adds the specified units to a date
 */
export const addTime = (date: DateObject = null, amount: number, units: string): Date =>
  add(getDateObject(date), {
    [units]: amount,
  });

/**
 * @param   {DateObject} date
 * @param   {number}     amount
 * @param   {string}     units
 * @returns {Date} subtracts the specified units from a date
 */
export const subtractTime = (date: DateObject = null, amount: number, units: string): Date =>
  sub(getDateObject(date), {
    [units]: amount,
  });

/**
 * @param   {DateObject} date
 * @returns {Date} represents a date at the beginning of the day
 */
export const getDateFromStartOfDay = (date: DateObject = null): Date =>
  startOfDay(getDateObject(date));

/**
 * @param   {DateObject} date
 * @returns {Date} represents a date at the end of the day
 */
export const getEndOfDay = (date: DateObject = null): Date => endOfDay(getDateObject(date));

/**
 * @param   {DateObject} date
 * @returns {number} gets the day of the week from an ISO valid date
 */
export const getISOWeekDay = (date: DateObject = null): number => getISODay(getDateObject(date));

/**
 * @param   {DateObject} date
 * @returns {number} gets the day of the week the date is on
 */
export const getWeekDay = (date: DateObject = null): number => getDay(getDateObject(date));

/**
 * @param   {DateObject} date
 * @returns {Date} returns a utc date to compare universally
 */
export const getUTCDate = (date?: DateObject): Date => new Date(getDateObject(date).toUTCString());

/**
 * @param   {DateObject} date
 * @param   {DateObject} start
 * @param   {DateObject} end
 * @returns {boolean} returns whether a date is in between two other dates
 */
export const isDateBetween = (
  date: DateObject = null,
  start: DateObject = null,
  end: DateObject = null,
): boolean => {
  const dateObject = getDateObject(date);
  const startObject = getDateObject(start);
  const endObject = getDateObject(end);

  return isBefore(dateObject, endObject) && isAfter(dateObject, startObject);
};

/**
 * @param   {DateObject}                 date
 * @param   {Intl.DateTimeFormatOptions} format
 * @returns {string} returns a string date formatted in various ways per the [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat) spec
 */
export const formatDateLocally = (
  date: DateObject = null,
  formatting: Intl.DateTimeFormatOptions,
): string => {
  const dateObject = getDateObject(date);

  return Intl.DateTimeFormat('en-us', formatting).format(dateObject);
};

/**
 * @param   {string} date
 * @param   {string} offset
 * @returns {string} returns a javascript Date matching a timezone offset
 */
const getDateInTimezone = (date: string, offset: string): Date => {
  const dstOffset = getDSTOffset(new Date(date));
  const dstOffsetDate = sub(new Date(date), {
    hours: Math.abs(dstOffset),
  });

  const utcDate = zonedTimeToUtc(dstOffsetDate, getClientOffset());

  return utcToZonedTime(format(utcDate, "yyyy-MM-dd'T'HH:mm:ssXXX"), offset);
};

/**
 * @param   {string} date
 * @param   {string} format
 * @param   {string} offset
 * @returns {string} returns a formatted string date matching a timezone offset
 */
export const getFormattedDateInTimezone = (
  date: string,
  formatting: string,
  offset: string,
): string => {
  // Validate if the date string is a valid date, preventing passed values such as 'asap'
  const parsedDate = parseISO(date);
  if (!isValid(parsedDate)) {
    return '';
  }
  const utcDate = getDateInTimezone(date, offset);

  return format(utcDate, formatting);
};

export const isValidDate = (date: Date) => isValid(date);
