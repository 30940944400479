import styled, { createGlobalStyle } from 'styled-components';
import { COLORS, LAYOUT, ORDER } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { parseCustomUserAgent, getMobileStyles } from '@/utils/mobile';

interface GlobalStyleProps {
  hasContextualCart: boolean;
}

const isCOOMobile_iOS = parseCustomUserAgent()?.mobile_ios;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${({ theme, hasContextualCart }) => {
    const primaryFont = getFontStyles(theme.text.primary_font);
    const secondaryFont = getFontStyles(theme.text.secondary_font);
    return `
    :root {
      --layer-0: 0;
      --layer-1: 1;
      --layer-2: 2;
      --layer-3: 3;
      --layer-4: 4;
      --layer-5: 5;
      --layer-10: 10;
      --layer-20: 20;
      --layer-50: 50;
      --layer-100: 100;

      --neg-size-7: -1.75rem;
      --neg-size-6: -1.5rem;
      --neg-size-5: -1.25rem;
      --neg-size-4: -1rem;
      --neg-size-4: -.75rem;
      --neg-size-2: -.5rem;
      --neg-size-1: -.25rem;
      --size-1: .25rem;
      --size-2: .5rem;
      --size-3: .75rem;
      --size-4: 1rem;
      --size-5: 1.25rem;
      --size-6: 1.5rem;
      --size-7: 1.75rem;
      --size-8: 2rem;
      --size-12: 3rem;
      --size-16: 4rem;
      --size-18: 4.5rem;
      --size-20: 5rem;
      --size-24: 6rem;
      --size-32: 8rem;
      --size-48: 12rem;
      --size-72: 18rem;
      --size-80: 20rem;
      --size-96: 24rem;
      --size-128: 32rem;

      --size-xl: 32rem;
      --size-2xl: 48rem;
      --size-3xl: 64rem;
      --size-4xl: 72rem;
      --size-5xl: 96rem;

      --safe-offset--ascending: 0;
      --safe-offset--leading: 0;
      --safe-offset--trailing: 0;
      --safe-offset--descending: ${hasContextualCart ? 'var(--size-20)' : '0'};

      @media (min-width: 768px) {
        --safe-offset--descending: 0;
      }

      /* Configs */
      --config-text__primary-text-size: ${primaryFont.fontSize};
      --config-text__primary-text-color: ${primaryFont.color};
      --config-text__secondary-text-size: ${secondaryFont.fontSize};
      --config-text__secondary-text-color: ${secondaryFont.color};

      --config-global__body-color: ${theme.global.body_color};
      --config-global__error-color: ${theme.global.error_color};
      --config-global__border-radius: ${theme.global.border_radius};
      --config-global__loading-bar-color: ${theme.global.loading_bar_color};
      --config-global__primary-active-color: ${theme.global.primary_active_color};
      --config-global__primary-border-color: ${theme.global.primary_border_color};
      --config-global__secondary-body-color: ${theme.global.secondary_body_color};

      --config-primary-font-family__bold: ${theme.primary_font.bold.font_family};
      --config-primary-font-family__light: ${theme.primary_font.light.font_family};
      --config-primary-font-family__medium: ${theme.primary_font.medium.font_family};
      --config-primary-font-family__regular: ${theme.primary_font.regular.font_family};

      --config-secondary-font-family__bold: ${theme.secondary_font.bold.font_family};
      --config-secondary-font-family__light: ${theme.secondary_font.light.font_family};
      --config-secondary-font-family__medium: ${theme.secondary_font.medium.font_family};
      --config-secondary-font-family__regular: ${theme.secondary_font.regular.font_family};

      --config-primary-button__background-color: ${theme.buttons.primary_button_background_color};
      --config-primary-button__hover__background-color: ${
        theme.buttons.primary_button_background_hover_color
      };
      --config-primary-button__color: ${theme.buttons.primary_font.color};

      --badge__background-color: ${theme.buttons.primary_button_background_color};
      --badge__color: ${theme.buttons.primary_font.color};
      --badge__font-family: ${theme.primary_font.regular.font_family};

      --navigation-item__border-color: ${theme.global.secondary_body_color};
      --navigation-item__color: ${secondaryFont.color ?? theme.global.primary_active_color};
      --navigation-item__font-family: ${theme.secondary_font.bold.font_family};
      --navigation-item__font-size: ${primaryFont.fontSize ?? '16px'};

      --config-checkout_font_color: ${theme.checkout.font.color ?? 'inherit'};
    }

    body {
      background-color: ${theme.global.body_color || COLORS.BODY};
      color: ${primaryFont.color ?? COLORS.TEXT};
      font-family: ${theme.primary_font.regular.font_family};
      font-size: ${primaryFont.fontSize ?? '16px'};
      margin-bottom: ${isCOOMobile_iOS ? `25px` : `0px;`};
    }

    h2 {
      font-size: 16px;
      font-family: ${theme.primary_font.medium.font_family};
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }

    textarea, select, input, button {
      font-family: ${theme.primary_font.regular.font_family};
    }

    a, button {
      color: ${primaryFont.color || COLORS.TEXT};
    }
  `;
  }}


`;

export const StyledLayout = styled.div`
  a,
  button {
    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  input:focus {
    outline: none;
  }
`;

interface StyledContentProps {
  $backgroundOverride?: boolean;
}

export const StyledContent = styled.div<StyledContentProps>`
  // Background color/image overrides
  ${({ $backgroundOverride, theme }) =>
    $backgroundOverride &&
    `
    background-color: ${theme.global.secondary_body_color};
  `}

  > div {
    height: 100%;
    margin: 0 auto;

    @media print {
      padding-top: 20px;
    }
  }

  // handles body & the /android welcome screen styles
  ${isCOOMobile_iOS && { ...getMobileStyles(['paddingBottom']) }}}
`;

export const AccessibilityAid = styled.a`
  background: white;
  position: fixed;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  &:focus {
    top: 0;
    z-index: ${ORDER.SKIP_TO_CONTENT};
    height: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
    padding: 0 10px;
    clip: auto;
    font-weight: bold;
    line-height: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
    color: ${COLORS.TEXT};
    text-decoration: none;
    width: auto;

    @media (min-width: 768px) {
      height: ${LAYOUT.HEADERHEIGHT}px;
      line-height: ${LAYOUT.HEADERHEIGHT}px;
    }
  }
`;
