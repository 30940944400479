import { type CheckoutBasket, CONVEYANCE_TYPES, type Strings } from '@koala/sdk';
import { addDays, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DATE_FORMAT } from '@/constants/dates';
import { formatDate } from '@/utils/dates';
import { DELIVERY_TIME_WANTED_MODES } from '@/constants/global';
import { safelyGetString } from '@/utils/stringHelpers';

/**
 * @param input.canOrderAhead Does the location support ordering for future days?
 * @param input.advanceDays The number of days in advance an order can be made for.
 */
export function getAvailableDays(
  input: { canOrderAhead: true; advanceDays: number } | { canOrderAhead: false },
) {
  const today = new Date();
  // If ordering ahead is enabled, create an array of orderable future days.
  if (input.canOrderAhead) {
    return Array.from({ length: input.advanceDays + 1 }, (_, i) => {
      const day = addDays(today, i);
      return {
        label: format(day, DATE_FORMAT.LABEL),
        value: format(day, DATE_FORMAT.YEAR_MONTH_DAY_DASHED),
      };
    });
  }
  return [
    {
      label: format(today, DATE_FORMAT.LABEL),
      value: format(today, DATE_FORMAT.YEAR_MONTH_DAY_DASHED),
    },
  ];
}

export const getTimeslot =
  (strings: Strings, checkoutBasket: CheckoutBasket | undefined, storeTimezone: string) =>
  (value: string) => {
    if (value === DELIVERY_TIME_WANTED_MODES.ASAP) {
      const label = safelyGetString(strings, 'handoff_time.asap_select_option_text');

      return {
        label: checkoutBasket?.time_estimate_minutes
          ? `${label} (${checkoutBasket?.time_estimate_minutes} min)`
          : label,
        value,
      };
    }

    const timeSlotInTimezone = utcToZonedTime(value, storeTimezone);

    return {
      label: formatDate(timeSlotInTimezone, DATE_FORMAT.HOURS_WITH_MINUTES),
      value,
    };
  };
/*
 * Conveyance modes are types using multiple Enum values
 * which are difficult to pass around in the code, so we map them
 * all to CONVEYANCE_TYPES. These enums should eventually be reconciled
 * and use `as const` instead of an Enum, which work poorly in JavaScript
 */
export function getMappedConveyanceType(type: string) {
  switch (type) {
    case 'delivery':
      return CONVEYANCE_TYPES.DELIVERY;
    case 'Delivery':
      return CONVEYANCE_TYPES.DELIVERY;
    case 'pick_up':
      return CONVEYANCE_TYPES.PICKUP;
    case 'Pickup':
      return CONVEYANCE_TYPES.PICKUP;
    case 'curbside':
      return CONVEYANCE_TYPES.CURBSIDE;
    case 'Curbside':
      return CONVEYANCE_TYPES.CURBSIDE;
    case 'dine_in':
      return CONVEYANCE_TYPES.DINEIN;
    case 'Dine-In':
      return CONVEYANCE_TYPES.DINEIN;
    case 'drive_thru':
      return CONVEYANCE_TYPES.DRIVETHRU;
    case 'Drive-Thru':
      return CONVEYANCE_TYPES.DRIVETHRU;
    case 'drive_in':
      return CONVEYANCE_TYPES.DRIVEIN;
    case 'Drive-In':
      return CONVEYANCE_TYPES.DRIVEIN;

    default:
      return CONVEYANCE_TYPES.PICKUP;
  }
}
