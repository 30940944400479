import { type ParsedUrlQuery } from 'querystring';
import { type Location, type LocationOperatingHours, type WebConfig } from '@koala/sdk';
import {
  addDays,
  isSameDay,
  isWithinInterval,
  parseISO,
  isToday,
  startOfToday,
  startOfDay,
} from 'date-fns';
import { z } from 'zod';
import { utcToZonedTime, format } from 'date-fns-tz';
import { supportsBrandId } from './config';
import { DATE_FORMAT } from '@/constants/dates';
import { LOCATION_STATUSES, SUPPORTED_COUNTRIES } from '@/constants/locations';
import { REGEX } from '@/constants/validation';
import { type HoursSlot, type WeekDayHours } from '@/types/locations';
import {
  formatDate,
  isDateBetween,
  isDateSameOrAfter,
  getUTCDate,
  getFormattedDateInTimezone,
  getSanitizedOffset,
  addTime,
  isDateSameOrBefore,
  isDateBefore,
  subtractTime,
} from '@/utils/dates';

export const ORDER_ASAP = 'asap';

export const googleMapsUrl = (cachedData: Location['cached_data']) =>
  cachedData
    ? `https://www.google.com/maps/place/${encodeURIComponent(
        `${cachedData.street_address}, ${cachedData.state} ${cachedData.zip}`,
      )}`
    : '#';

/**
 * Derive today's operating hours
 *
 * @param {operatingHours} array
 *
 * @return {LocationOperatingHours[]}
 */
export const getTodaysHourSlots = (operatingHours: LocationOperatingHours[]) =>
  operatingHours.filter((slot: LocationOperatingHours) =>
    isSameDay(getUTCDate(), getUTCDate(slot.start)),
  );

/**
 * Derive future operating hours including today
 *
 * @param {operatingHours} array
 * @param {utcOffset} number
 *
 * @return {LocationOperatingHours[]}
 */
export const getNextHourSlots = (operatingHours: LocationOperatingHours[]) =>
  operatingHours.filter((slot: LocationOperatingHours) =>
    isDateSameOrAfter(getUTCDate(slot.start), getUTCDate()),
  );

/**
 * Derive today's operating hours
 *
 * @param {operatingHours} array
 *
 * @return {string}
 */
export const deriveTodaysHours = (location: Location, utcOffset: number): string => {
  const operatingHours = getOperatingHoursForLocation(location);

  // Find all of todays whose type is 'operating_hours' (i.e. not delivery)
  const todaysSlots = getTodaysHourSlots(operatingHours);

  // If no available today slots or too many
  if (!todaysSlots.length || todaysSlots.length > 1) {
    return '';
  }

  const startTime = todaysSlots[0].start;
  const endTime = todaysSlots[0].end;

  const timeFormat = DATE_FORMAT.HOURS_WITH_MINUTES;
  const offset = getSanitizedOffset(utcOffset);
  const closingTime = getFormattedDateInTimezone(endTime, timeFormat, offset);

  if (isDateBetween(getUTCDate(), startTime, endTime)) {
    return `${getOperatingHoursString(location)} until ${closingTime}`;
  }

  return `${getOperatingHoursString(location)} from ${getFormattedDateInTimezone(
    startTime,
    timeFormat,
    offset,
  )} to ${closingTime}`;
};

/**
 * Derive formatted date/time string
 *
 * @param {string} timestamp
 * @param {string} dateFormat
 * @param {number} utcOffset
 *
 * @return {string}
 */
export const deriveFormattedDateTimeString = (
  timestamp: string,
  format: string,
  offset = '+00',
): string => {
  if (!timestamp || !format) {
    return '';
  }

  return getFormattedDateInTimezone(timestamp, format, offset);
};

export const getFormattedSlotHours = (
  start: string,
  end: string,
  formatting: string,
  offset: string,
): HoursSlot => {
  return {
    start: getFormattedDateInTimezone(start, formatting, offset).toLowerCase().replace(' ', ''),
    end: getFormattedDateInTimezone(end, formatting, offset).toLowerCase().replace(' ', ''),
  };
};

function formatOperatingHoursSlot(hours: LocationOperatingHours, utcOffset: string): HoursSlot {
  return {
    start: getFormattedDateInTimezone(hours.start, DATE_FORMAT.HOURS_WITH_MINUTES, utcOffset)
      .toLowerCase()
      .replace(' ', ''),
    end: getFormattedDateInTimezone(hours.end, DATE_FORMAT.HOURS_WITH_MINUTES, utcOffset)
      .toLowerCase()
      .replace(' ', ''),
  };
}

/**
 * Accepts a list of a store's hours and returns a list of all operating
 * hours in the next week, formatted in the user's local timezone.
 *
 * @param operatingHours list of operating hours.
 * @param locationUtcOffset the store location's UTC offset.
 */
export function deriveWeeklyHours(
  operatingHours: LocationOperatingHours[] | undefined,
  locationUtcOffset: number,
): WeekDayHours[] | null {
  if (!operatingHours) {
    return null;
  }

  // Prepare the location's UTC offset so it can be used to format
  // the location's hours in the user's local timezone.
  const sanitizedOffset = getSanitizedOffset(locationUtcOffset);
  const today = startOfToday();
  const nextWeek = addDays(today, 7);

  // Store hours keyed by weekday for easy retrieval.
  const hoursByWeekday: Record<string, HoursSlot[]> = {};
  operatingHours.forEach((h) => {
    const startTime = parseISO(h.start);
    // If the hours *start* in the next week...
    if (isWithinInterval(startTime, { start: today, end: nextWeek })) {
      // Format the slot and store it by weekday. Eg: `{ Wednesday: [] }`
      if (h.day_of_week in hoursByWeekday) {
        hoursByWeekday[h.day_of_week].push(formatOperatingHoursSlot(h, sanitizedOffset));
      } else {
        hoursByWeekday[h.day_of_week] = [formatOperatingHoursSlot(h, sanitizedOffset)];
      }
    }
  });

  // Return an array of 7 days starting with today and corresponding hour slots.
  return Array.from({ length: 7 }, (_, i) => {
    const day = addDays(today, i);
    const weekday = formatDate(day, DATE_FORMAT.WEEKDAY);
    return {
      weekday,
      slots: hoursByWeekday[weekday] ?? [],
      isSelected: isToday(day),
    };
  });
}

/**
 * Determine if store is currently open
 *
 * Must compare in local time zone via formatDate() otherwise hours will be off
 * EX: A brand closes at 8:30pm == 2024-09-10T20:30:00.000Z would return as "open" at 12:00 midnight == 2024-09-10T07:02:36.665Z
 *
 * @param {operatingHours} LocationOperatingHours[]
 *
 * @return {boolean}
 */
export const isStoreCurrentlyOpen = (operatingHours?: LocationOperatingHours[]) => {
  const now = new Date();
  const currentDateISO = formatDate(now, DATE_FORMAT.ISO_DATE);
  const currentDay = now.toISOString().slice(0, 10);
  const nextDayISO = formatDate(addTime(currentDateISO, 1, 'days'), DATE_FORMAT.ISO_DATE);
  const nextDay = nextDayISO.slice(0, 10);

  const todaysHours = operatingHours?.filter((loc) => {
    //checking tomorrows hours accounts for UTC formatted dates in the `location.operating_hours` array
    if (loc.start.slice(0, 10) == currentDay || loc.start.slice(0, 10) == nextDay) return loc;
  });

  const withinOperatingHours = todaysHours?.find((hoursObject) => {
    const _30MinutesBeforeClose = new Date(
      new Date(hoursObject.end).getTime() - 1000 * 60 * 30,
    ).toISOString();

    const checkTodaysHours =
      formatDate(new Date(hoursObject.start), DATE_FORMAT.ISO_DATE) <= currentDateISO &&
      formatDate(_30MinutesBeforeClose, DATE_FORMAT.ISO_DATE) >= currentDateISO;

    const checkTomorrowsHours =
      formatDate(new Date(hoursObject.start), DATE_FORMAT.ISO_DATE) <= nextDayISO &&
      formatDate(_30MinutesBeforeClose, DATE_FORMAT.ISO_DATE) >= nextDayISO;

    return checkTodaysHours || checkTomorrowsHours;
  });

  return Boolean(withinOperatingHours);
};

interface StoreTimeInfo {
  storeUTCOffset: number | undefined;
  storeTimezone: string;
  isOpen: boolean;
  hasRemainingHoursToday: boolean;
  storeDateTimeNow: Date;
  storeDate: string;
  startOfTomorrowInStore: string;
  storeLocalDateTimes: string;
}

export const getStoreTimeInfo = (location?: Location): StoreTimeInfo => {
  const now = new Date();
  const storeUTCOffset = location?.cached_data?.utc_offset;
  const storeTimezone = storeUTCOffset !== undefined ? getSanitizedOffset(storeUTCOffset) : '';
  const storeLocalDateTime = utcToZonedTime(now, storeTimezone);
  const storeLocalDateTimeISO = format(storeLocalDateTime, "yyyy-MM-dd'T'HH:mm:ssXXX");

  // Handle the tomorrow date calculations
  const startOfDayDate = startOfDay(storeLocalDateTime);
  const tomorrowDate = addDays(startOfDayDate, 1);
  const tomorrowDateISO = format(tomorrowDate, "yyyy-MM-dd'T'HH:mm:ssXXX");

  // Default values if no location or UTC offset
  if (!location?.operating_hours?.length || storeUTCOffset === undefined) {
    return {
      storeUTCOffset,
      storeTimezone,
      isOpen: false,
      hasRemainingHoursToday: false,
      storeDateTimeNow: storeLocalDateTime,
      storeDate: format(storeLocalDateTime, 'yyyy-MM-dd'),
      startOfTomorrowInStore: tomorrowDateISO,
      storeLocalDateTimes: storeLocalDateTimeISO,
    };
  }

  // Get store's current time and hours
  const { todaysHours, inStoreTime } = getHours(location.operating_hours, storeUTCOffset);

  // Calculate operating status
  let isOpen = false;
  let hasRemainingHoursToday = false;

  for (const hours of todaysHours) {
    const startTime = utcToZonedTime(new Date(hours.start), storeTimezone);
    const endTime = utcToZonedTime(new Date(hours.end), storeTimezone);

    const lastOrderTime = subtractTime(endTime, 15, 'minutes');

    // Check if currently open
    if (
      isDateSameOrAfter(inStoreTime, startTime) &&
      isDateSameOrBefore(inStoreTime, lastOrderTime)
    ) {
      isOpen = true;
    }

    // Check if there are any remaining hours today
    if (isDateBefore(inStoreTime, lastOrderTime)) {
      hasRemainingHoursToday = true;
    }

    if (isOpen && hasRemainingHoursToday) break;
  }

  // Get store's current date and tomorrow
  const storeDate = format(storeLocalDateTime, 'yyyy-MM-dd');

  return {
    storeUTCOffset,
    storeTimezone,
    isOpen,
    hasRemainingHoursToday,
    storeDateTimeNow: storeLocalDateTime,
    storeDate,
    startOfTomorrowInStore: tomorrowDateISO,
    storeLocalDateTimes: storeLocalDateTimeISO,
  };
};

/**
 * Determine if a location is currently active
 */
export const checkLocationStatus = (location: Location) => {
  if (location.status_id === LOCATION_STATUSES.INACTIVE) {
    window.location.href = '/';
  }
};

/** Returns the canonical ID for a location depending on the ID source. */
export function getLocationId(loc: Location, cfg: WebConfig): string | number {
  // @ts-expect-error either the location ID or brand ID will be present.
  return supportsBrandId(cfg) ? loc.brand_id : loc.id;
}

/**
 * Splits a list of locations by country—Canada or USA.
 *
 * @param locations locations to split.
 */
export function splitLocationsByCountry(
  locations: Location[],
): Record<SUPPORTED_COUNTRIES, Location[]> {
  const canadianLocations: Location[] = [];
  const usLocations: Location[] = [];
  locations.forEach((loc) => {
    if (loc.cached_data?.country.toLocaleUpperCase() === 'CA') {
      canadianLocations.push(loc);
    } else if (loc.cached_data?.country.toLocaleUpperCase() === 'US') {
      usLocations.push(loc);
    }
  });
  return {
    [SUPPORTED_COUNTRIES.CANADA]: canadianLocations,
    [SUPPORTED_COUNTRIES.USA]: usLocations,
  };
}

/**
 * Function that checks if given value is valid US zip code or a Canadian postal code
 */
export function isValidZip(value: string) {
  return REGEX.ZIP_CODE_USA_AND_POSTAL_CODE_CANADA.test(value);
}

interface LocationsOptions {
  label: string;
  value: number;
}

export const getLocationDataForDropdown = (locations: Location[]): LocationsOptions[] => {
  // Assemble options array for the select, with label and value
  return locations
    .map((location) => ({
      label: location.label,
      value: location.id,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));
};

const storeRouteParamsSchema = z.string().array().nonempty();

export interface StoreRouteParams {
  id: string;
  name: string | undefined;
  catId: string | undefined;
  catName: string | undefined;
  productId: string | undefined;
  productName: string | undefined;
}

export function parseLocationRouteParams(q: ParsedUrlQuery): StoreRouteParams {
  const parsed = storeRouteParamsSchema.parse(q.id);
  return {
    id: parsed[0],
    name: parsed[1],
    catId: parsed[2],
    catName: parsed[3],
    productId: parsed[4],
    productName: parsed[5],
  };
}
/**
 * Get the operating hours for a location.
 * `business_hours` will be the same as the `operating_hours` for Olo and Square locations.
 * Chowly locations will have only `operating_hours`.
 */
export const getOperatingHoursForLocation = (location?: Location) =>
  supportsStoreHours(location) ? location?.business_hours ?? [] : location?.operating_hours ?? [];

/**
 * Determine if location supports store hours (business_hours)
 */
export const supportsStoreHours = (location?: Location) =>
  Boolean(location?.business_hours && location?.business_hours.length > 0);

/**
 * Get string for location's operating hours based on if location supports store hours  (business_hours)
 */
export const getOperatingHoursString = (location?: Location) =>
  supportsStoreHours(location) ? 'Open' : 'Online ordering available';

export const getHours = (operatingHours: LocationOperatingHours[], utcOffset: number) => {
  // Get current time in UTC
  const nowUtc = new Date();
  const timezone = getSanitizedOffset(utcOffset);
  const inStoreTime = utcToZonedTime(nowUtc, timezone);

  // Format current date for comparison
  const currentDate = format(inStoreTime, 'yyyy-MM-dd');

  // Filter hours for the current day
  const todaysHours = operatingHours.filter((loc) => {
    const startTime = utcToZonedTime(new Date(loc.start), timezone);
    const endTime = utcToZonedTime(new Date(loc.end), timezone);
    const startDate = format(startTime, 'yyyy-MM-dd');
    const endDate = format(endTime, 'yyyy-MM-dd');

    return startDate === currentDate || endDate === currentDate;
  });

  return {
    todaysHours,
    timezone,
    inStoreTime,
  };
};
